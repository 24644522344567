import { Box, Flex } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { useEffect, useState } from "react";

export const Swiper = ({
    style = {},
    timeToChange = 5,
    items = [],
}: {
    style?: React.CSSProperties,
    timeToChange?: number,
    items: any[],
}) => {

    const [seconds, setSeconds] = useState(0);
    // const [currentPlace, setCurrentPlace] = useState(<></>);
    const [currentIndex, setCurrentIndex] = useState(0);
    const interval = useInterval(() => setSeconds((s) => s + 1), 1000);
    useEffect(() => {
        interval.start();
        return interval.stop;
    }, [ interval ]);

    useEffect(() => {
        let index = Math.floor(seconds / timeToChange);
        if (index >= items.length) {
            index = 0;
            setSeconds(0);
        }
        // setCurrentPlace(items[index]);
        setCurrentIndex(index);
    }, [seconds, timeToChange, items]);

    return (
        <Flex
            style={{
                ...style,
                overflow: 'hidden',
                position: 'relative',
            }}
            p={'md'}
            h={'100%'}
            align={'center'}
            justify={'center'}
        >
            {
                items.map((place, index) => (
                    <Box
                        key={index}
                        style={{
                            width: '100%',
                            height: '100%',
                            transition: 'all 0.5s ease',
                            position: 'absolute',
                            left: 0,
                            opacity: index === currentIndex ? 1 : 0,
                            top: index === currentIndex ? 0 : (index > currentIndex || index < currentIndex - 1 || (currentIndex === 0 && index === items.length - 1)) ? '100%' : '-100%',
                        }}
                    >
                        {place}
                    </Box>
                ))
            }
        </Flex>
    );

}