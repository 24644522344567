import { useEffect, useRef, useState } from "react";
import { Box, Divider, Flex, Text, useDirection, useMantineColorScheme } from "@mantine/core";
import { useFetch, useInterval } from "@mantine/hooks";
import { Logo } from "./logo";
import { useTranslation } from "react-i18next";

export const WiresFeed = (props: {
    step?: number,
}) => {

    const [news, setNews] = useState<any[]>([
        // {
        //     id: 1,
        //     title: '“حزب الله”: دمرنا “ميركافا” إسرائيلية وقصفنا مقرا وقاعدة ومخازن للجيش الإسرائيلي بالكاتيوشا',
        // },
        // {
        //     id: 2,
        //     title: 'عبدالرؤوف بطيخ : تحديث.قراءات ماركسية :حول الأزمة الاقتصادية فى (الولايات المتحدة الامريكية n[2])مجلة الصراع الطبقى.فرنسا',
        // },
        // {
        //     id: 3,
        //     title: 'أحمد رباص : الحزب الاشتراكي الموحد يحدد موقفه من عدة قضايا وطنية وإقليمية ودولية',
        // },
        // {
        //     id: 4,
        //     title: 'عملية أمريكية عراقية مشتركة تقتل 14 من عناصر تنظيم الدولة',
        // },
        // {
        //     id: 5,
        //     title: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        // },
        // {
        //     id: 6,
        //     title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        // },
        // {
        //     id: 7,
        //     title: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        // },
        // {
        //     id: 8,
        //     title: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        // },
        // {
        //     id: 9,
        //     title: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        // },
        // {
        //     id: 10,
        //     title: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        // }
    ]);

    // const { data, loading, error, refetch, abort } = useFetch<any>(
    const { data, error, refetch, abort } = useFetch<any>(
        'https://wires.964media.com/ar/posts?limit=100',
    );

    useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 10000);
        return () => {
            clearInterval(interval);
            abort();
        }
    }, [refetch, abort]);

    const { dir } = useDirection();
    const { colorScheme } = useMantineColorScheme();
    const tickerWarperRef = useRef(null);
    const tickerRef = useRef(null);
    const [currOffset, setCurrOffset] = useState(0);
    const interval = useInterval(() => {
        if (!tickerRef.current) return;
        if (!tickerWarperRef.current) return;
        const ticker: any = tickerRef.current;
        const tickerWarper: any = tickerWarperRef.current;
        const step = props.step ?? 2;
        setCurrOffset(currOffset + step * 1);
        ticker.style.transform = `translateY(${dir === 'ltr' ? currOffset : -currOffset}px)`;
        if (Math.abs(currOffset) >= ticker.offsetHeight + tickerWarper.offsetHeight) {
            setCurrOffset(0);
            if (data && !error) {
                setNews(data);
            }
        }
    }, 20);

    useEffect(() => {
        if (data && !error) {
            setNews(data);
        }
        interval.start();
        return interval.stop;
    }, [data, error, interval]);

    const { t } = useTranslation();

    return (
        <Box
            ref={tickerWarperRef}
            style={{
                position: 'relative',
                overflow: 'hidden',
                // backgroundColor: '#fff',
                height: '100%',
            }}
        >
            <Flex
                ref={tickerRef}
                style={{
                    ...(dir === 'ltr' ? {
                        bottom: '100%',
                    } : {
                        bottom: '-100%',
                    }),
                    position: 'relative',
                }}
                // w="max-content"
                gap={'xl'}
                direction={'column'}
                px={'md'}
            >
                {
                    news.map((item, index) => {
                        // if title end with ... skip it
                        if (item.title.endsWith('...')) {
                            return <></>;
                        }
                        return (
                            <>
                                <Flex gap={'md'} direction={'column'}>
                                    <Flex gap={'md'} wrap={'wrap'} align={'center'}>
                                        <Text
                                            key={index}
                                            size={'2rem'}
                                            fw={'bold'}
                                            style={{
                                                color: '#fff',
                                                lineHeight: '2.7rem',
                                            }}
                                        >
                                            {item.title.replace(/&#(\d+);/g, (match: any, dec: any) => {
                                                let str = String.fromCharCode(dec);
                                                str = str.replace(/quot;/g, '"');
                                                str = str.replace(/amp;/g, '&');
                                                str = str.replace(/lt;/g, '<');
                                                str = str.replace(/gt;/g, '>');
                                                str = str.replace(/nbsp;/g, ' ');
                                                str = str.replace(/apos;/g, "'");
                                                return str;
                                            })}
                                        </Text>
                                        {
                                            item.source.icon && (
                                                <img src={item.source.icon} alt={item.source.name} height={30} />
                                            )
                                        }
                                        <Text
                                            style={{
                                                color: '#fff',
                                                fontSize: '1.6rem',
                                            }}
                                        >
                                            {item.source.name}
                                        </Text>
                                    </Flex>
                                    <Divider />
                                </Flex>
                                {/* {
                                    index !== news.length - 1 && (
                                        <img src="/assets/plus.svg" width={((tickerWarperRef.current as any)?.offsetHeight ?? 1) * 0.8} alt="plus" />
                                    )
                                } */}
                            </>
                        );
                    })
                }
            </Flex>
            <Flex justify={'center'} align={'center'} direction={'column'}
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    backgroundColor: '#fff',
                    borderRadius: '0 10px 0 0',
                }}
                px={'lg'}
                py={'xs'}
            >
                <Box
                    style={{
                        backgroundColor: 'white',
                        padding: '10px',
                        borderRadius: '10px',
                    }}
                >
                    <img width={100} src="https://api.qrserver.com/v1/create-qr-code/?size=350x350&data=https://964media.com/wires" alt="QR Code" />
                </Box>
                <Text
                    size={'1.5rem'}
                    fw={'bold'}
                    style={{
                        color: '#000',
                    }}>
                    {t('تصفح الأخبار')}
                </Text>
            </Flex>
        </Box>
    );

};