import './App.css';
import { Box, useDirection, useMantineColorScheme } from '@mantine/core';
import Router from './router';
import { useDocumentTitle } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

function App() {
    const { t } = useTranslation();
    useDocumentTitle( t( "964stream | 964media" ) );

    const colorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    const themeIns = useMantineColorScheme();
    themeIns.setColorScheme(colorScheme);

    // i18n
    // first part of the url path is the language 'ar' or 'en' or 'ku'
    // if not found or not one of the above then default to 'ar'
    const language = window.location.pathname.split('/')[1] || 'ar';
    if (!['ar', 'en', 'ku'].includes(language)) {
        window.location.pathname = '/ar';
    }

    const { i18n } = useTranslation();
    i18n.changeLanguage(language);

    const { setDirection, dir } = useDirection();
    useEffect(() => {
        setDirection(language === 'ar' ? 'rtl' : 'ltr');
    }, [language]);

    return (
        <Box>
            <Router />
        </Box>
    );
}

export default App;
