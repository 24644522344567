import { Box, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { useSetState, useViewportSize } from '@mantine/hooks';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

export const StreamLayout = () => {

    const theme = useMantineTheme();
    const colorScheme = useMantineColorScheme();
    const [size, setSize] = useSetState({ width: 0, height: 0 });
    const { height, width } = useViewportSize();

    useEffect(() => {
        if (width > height) {
            let newWidth = Math.min(width, height * 16 / 9);
            let newHeight = newWidth * 9 / 16;
            setSize({ width: newWidth, height: newHeight });
        } else {
            let newHeight = Math.min(height, width * 9 / 16);
            let newWidth = newHeight * 16 / 9;
            setSize({ width: newWidth, height: newHeight });
        }
    }, [width, height, setSize]);

    return (
        <Box
            style={{
                width: '100vw',
                height: '100vh',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: colorScheme.colorScheme === 'dark' ? theme.black : theme.white,
            }}
        >
            <Box
                style={{
                    width: size.width,
                    height: size.height,
                    overflow: 'hidden',
                }}
            >
                <Outlet />
            </Box>
        </Box>
    );

};